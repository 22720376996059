/*

 Custom styles for Magalu organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

.container__header-provider {
    background: #0086FF !important;
}

.login--form-logo-image {
    width: 385px !important;
}

.login--logo,
.header--logo,
.header-provider--logo {
    content: none !important;
    mask: url('../../images/custom_organization/white_logo.svg');
    mask-size: 100%;
    mask-repeat: no-repeat;
    background-color: #ffffff;
    height: 60px;
    width: 100%;
}

.header-provider {
    width: 150px;
}

.carousel--color .carousel--item {
    position: relative;

    &::before {
        content: '';
        background-color: rgba($color: #000000, $alpha: 0.3);
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.program-selection-item .program-selection-item__program-buyer {

    display: none;
}

.invoice-list-item__items--cell--serial {
    display: none;
}

.invoice-list-item__items--cell--buyer {
    width: 13%;
    display:inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.container__invoices--header--serial {
    display: none;
}

.container__invoices--header--buyer {
    display: inline-flex;
    width: 18%;
}

.container__invoices--header--buyer {

    .sortable-column__items:first-child {
        width: 100%;
    }
}
